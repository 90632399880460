import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { urlFor, client } from '../../client'
import { AppWrap } from '../../wrapper'
import { MotionWrap } from '../../wrapper'

import './Footer.scss'

const Footer = () => {

    const [formData, setFormData] = useState({ name: '', email: '', message: '' })
    const [isFormSubmitted, setFormSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const { name, email, message } = formData

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value })

    }

    const handleSubmit = () => {
        setLoading(true)
        const contact = {
            _type: 'contact',
            name: name,
            email: email,
            message: message
        }

        client.create(contact).then((data) => {
            setLoading(false)
            setFormSubmitted(true)
        })
    }
    return (
        <>
            <h2 className='head-text'>
                Chat with Me
            </h2>
            <div className='app__footer-cards'>
                <div className='app__footer-card'>
                    <img src={images.email} />
                    <a href='mailto:info#derawllings.com'>info#derawllings.com</a>

                </div>
                <div className='app__footer-card'>
                    <img src={images.mobile} />
                    <a href='tel:+237678309084'>+237-678-309-084</a>

                </div>

            </div>

            {!isFormSubmitted ? <div className='app__footer-form app__flex'>
                <div className='app__flex'>
                    <input className='p-text' type='text' placeholder='your name' name='name' value={name} onChange={handleChange} />
                </div>
                <div className='app__flex'>
                    <input className='p-text' type='email' placeholder='your email' name='email' value={email} onChange={handleChange} />
                </div>
                <div>
                    <textarea
                        className='p-text'
                        placeholder='Your message'
                        value={message}
                        name="message"
                        onChange={handleChange}
                    >

                    </textarea>
                </div>

                <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Send Message' : 'Sending'}</button>

            </div> :
                <div>
                    <h4>Thanks for getting in touch</h4>
                </div>
            }
        </>
    )
}

export default AppWrap(
    MotionWrap(Footer, "app__footer"),
    "contact",
    "app__whitebg"
)