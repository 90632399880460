import React, { useState, useEffect } from 'react'
import './Work.scss'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'
import { animate, motion } from 'framer-motion'
import { AppWrap } from '../../wrapper'
import { MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
function Work() {


    const [activeFilter, setActiveFilter] = useState('All')
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
    const [works, setWorks] = useState([])
    const [filterWork, setFilterWork] = useState([])
    useEffect(() => {
        const query = '*[_type == "works"]'

        client.fetch(query).then((data) => {
            setWorks(data)
            setFilterWork(data)
            console.log(data)
        })
    }, [])
    const handleWorkFilter = (item) => {
        setActiveFilter(item)
        setAnimateCard([{ y: 100, opacity: 0 }])
        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }])
            if (item === 'All') {
                setFilterWork(works)
            } else {
                setFilterWork(works.filter((work) => work.tags.includes(item)))
            }
        }, 500)
    }

    return (
        <>
            <h2 className='head-text'>My Developer <span>Portfolio</span><br /> <span></span>
            </h2>
            <div className='app__work-filter'>
                {['All', 'SMART CONTRCT', 'WEB APP', 'MOBILE APP', 'WEB3 DAPP'].map((item, index) => (
                    <div key={index}
                        onClick={() => handleWorkFilter(item)}
                        className={`app__work-filter-item app__flex p-tetx ${activeFilter === item ? 'item-active' : ''}`}
                    >
                        {item}
                    </div>


                )
                )}
            </div>
            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="app__work-portfolio"
            >
                {filterWork.map((work, index) => (
                    <div className='app__work-item app__flex' key={index}>
                        <div className='app__work-img app__flex'>
                            <img src={urlFor(work.imgUrl)} alt={work.title} />

                            <motion.div
                                whileHover={{ opacity: [0., 1] }}
                                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0 }}
                                className="app__work-hover app__flex"
                            >
                                <a href={work.projectLink} target='_blank'>
                                    <motion.div
                                        whileHover={{ scale: [1., 0.9] }}
                                        whileInView={{ scale: [0, 1] }}
                                        className="app__flex"
                                        transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0 }}
                                    >

                                        <AiFillEye />

                                    </motion.div>
                                </a>

                                <a href={work.projectLink} target='_blank'>
                                    <motion.div
                                        whileHover={{ scale: [1., 0.9] }}
                                        whileInView={{ scale: [0, 1] }}
                                        className="app__flex"
                                        transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0 }}
                                    >

                                        <AiFillGithub />

                                    </motion.div>
                                </a>


                            </motion.div>
                        </div>
                        <div className='app__work-content app__flex'>
                            <h4 className='bolt-tetx'>{work.title}</h4>
                            <p className='p-tetx' style={{ marginTop: 10 }}>{work.description}</p>
                            <div className='app__work-tag app__flex'>
                                <p className='p-text'>{work.tags[0]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </motion.div>

        </>
    )
}
export default AppWrap(
    MotionWrap(Work, 'app__work'),
    'work',
    'app__primarybg'
)